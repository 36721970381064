const defaultFill = "#495E57";

export const Angle = ({ fill = defaultFill, height, direction = "left" }) => {
	if (direction === "up") {
		return (
			<svg viewBox="0 0 25 16" fill={fill} className={`icon icon-angle-up${height ? ` icon-${height}` : ""}`} xmlns="http://www.w3.org/2000/svg">
				<path d="M21.59 15.41L12.41 6.24997L3.23002 15.41L0.410019 12.59L12.41 0.589973L24.41 12.59L21.59 15.41Z" />
			</svg>

		)
	}

	if (direction === "right") {
		return (
			<svg viewBox="0 0 15 24" fill={fill} className={`icon icon-angle-right${height ? ` icon-${height}` : ""}`} xmlns="http://www.w3.org/2000/svg">
				<path d="M1.52588e-05 21.18L9.16002 12L1.52588e-05 2.82L2.82001 0L14.82 12L2.82001 24L1.52588e-05 21.18Z" />
			</svg>
		)
	}

	if (direction === "down") {
		return (
			<svg viewBox="0 0 25 16" fill={fill} className={`icon icon-angle-down${height ? ` icon-${height}` : ""}`} xmlns="http://www.w3.org/2000/svg">
				<path d="M3.23002 0.589965L12.41 9.74997L21.59 0.589966L24.41 3.40997L12.41 15.41L0.410019 3.40996L3.23002 0.589965Z" />
			</svg>

		)
	}

	if (direction === "left") {
		return (
			<svg viewBox="0 0 15 24" fill={fill} className={`icon icon-angle-left${height ? ` icon-${height}` : ""}`} xmlns="http://www.w3.org/2000/svg">
				<path d="M14.82 2.82L5.66001 12L14.82 21.18L12 24L8.67847e-06 12L12 -2.46532e-07L14.82 2.82Z" />
			</svg>
		)
	}

	return;
}

export const Check = ({ fill = defaultFill, height }) => {
	return (
		<svg viewBox="0 0 33 25" fill={fill} className={`icon icon-check${height ? ` icon-${height}` : ""}`} xmlns="http://www.w3.org/2000/svg">
			<path d="M12.2286 24.1451L0.799988 12.7166L4.02284 9.49372L12.2286 17.6766L29.5771 0.328003L32.8 3.57372L12.2286 24.1451Z" />
		</svg>
	)
}

export const Close = ({ fill = defaultFill, height }) => {
	return (
		<svg viewBox="0 0 32 32" fill={fill} className={`icon icon-close${height ? ` icon-${height}` : ""}`} xmlns="http://www.w3.org/2000/svg">
			<path d="M28 6.41714L25.5829 4L16 13.5829L6.41714 4L4 6.41714L13.5829 16L4 25.5829L6.41714 28L16 18.4171L25.5829 28L28 25.5829L18.4171 16L28 6.41714Z" />
		</svg>
	)
}

export const Basket = ({ fill = defaultFill, height }) => {
	return (
		<svg viewBox="0 0 32 32" fill={fill} className={`icon icon-basket${height ? ` icon-${height}` : ""}`} xmlns="http://www.w3.org/2000/svg">
			<path d="M27.6191 19.8095V24.3809H31.98V27.4285H27.6191V32H24.7119V27.4285H20.3509V24.3809H24.7119V19.8095H27.6191ZM15.99 16.7619C14.391 16.7619 13.0827 18.1333 13.0827 19.8095C13.0827 21.4857 14.391 22.8571 15.99 22.8571C17.589 22.8571 18.8973 21.4857 18.8973 19.8095C18.8973 18.1333 17.589 16.7619 15.99 16.7619ZM17.9524 28.9524H6.54136C5.20401 28.9524 4.08471 28.0076 3.75038 26.7276L0.0581454 12.6019C-5.21541e-08 12.4647 0 12.3276 0 12.1905C0 11.3524 0.654136 10.6666 1.45364 10.6666H8.41658L14.7834 0.685711C15.0597 0.243809 15.5248 0 15.99 0C16.4552 0 16.9203 0.243809 17.1966 0.670478L23.5634 10.6666H30.5264C31.3259 10.6666 31.98 11.3524 31.98 12.1905L31.9364 12.6019L30.5264 17.9962C29.6978 17.5238 28.7675 17.1276 27.7935 16.9295L28.6366 13.7143H3.3579L6.54136 25.9048H17.4436C17.4436 26.9714 17.6326 27.9923 17.9524 28.9524ZM11.9198 10.6666H20.0602L15.99 4.26667L11.9198 10.6666Z" />
		</svg>
	)
}

export const Facebook = ({ fill = defaultFill, height }) => {
	return (
		<svg viewBox="0 0 32 32" fill={fill} className={`icon icon-facebook${height ? ` icon-${height}` : ""}`} xmlns="http://www.w3.org/2000/svg">
			<path d="M32 16C32 7.16129 24.8387 0 16 0C7.16129 0 0 7.16129 0 16C0 24 5.80645 30.6452 13.4839 31.8065V20.6452H9.41935V16H13.4839V12.5161C13.4839 8.51613 15.871 6.25806 19.4839 6.25806C21.2903 6.25806 23.0968 6.58065 23.0968 6.58065V10.5161H21.0968C19.0968 10.5161 18.4516 11.7419 18.4516 13.0323V16H22.9032L22.1935 20.6452H18.4516V31.8065C26.129 30.6452 32 24 32 16Z" />
		</svg>
	)
}

export const Google = ({ fill = defaultFill, height }) => {
	return (
		<svg viewBox="0 0 32 32" fill={fill} className={`icon icon-google${height ? ` icon-${height}` : ""}`} xmlns="http://www.w3.org/2000/svg">
			<path d="M31.4839 16.3871C31.4839 25.5484 25.2258 32 16 32C7.09677 32 0 24.9032 0 16C0 7.16129 7.09677 0 16 0C20.2581 0 23.9355 1.6129 26.7097 4.19355L22.3226 8.3871C16.6452 2.90323 6.06452 7.03226 6.06452 16C6.06452 21.6129 10.5161 26.129 16 26.129C22.3226 26.129 24.7097 21.6129 25.0323 19.2258H16V13.7419H31.2258C31.3548 14.5806 31.4839 15.3548 31.4839 16.3871Z" />
		</svg>
	)
}

export const Instagram = ({ fill = defaultFill, height }) => {
	return (
		<svg viewBox="0 0 32 32" fill={fill} className={`icon icon-instagram${height ? ` icon-${height}` : ""}`} xmlns="http://www.w3.org/2000/svg">
			<path d="M16.0357 7.75C11.4643 7.75 7.82143 11.4643 7.82143 15.9643C7.82143 20.5357 11.4643 24.1786 16.0357 24.1786C20.5357 24.1786 24.25 20.5357 24.25 15.9643C24.25 11.4643 20.5357 7.75 16.0357 7.75ZM16.0357 21.3214C13.1071 21.3214 10.6786 18.9643 10.6786 15.9643C10.6786 13.0357 13.0357 10.6786 16.0357 10.6786C18.9643 10.6786 21.3214 13.0357 21.3214 15.9643C21.3214 18.9643 18.9643 21.3214 16.0357 21.3214ZM26.4643 7.46429C26.4643 8.53571 25.6071 9.39286 24.5357 9.39286C23.4643 9.39286 22.6071 8.53571 22.6071 7.46429C22.6071 6.39286 23.4643 5.53571 24.5357 5.53571C25.6071 5.53571 26.4643 6.39286 26.4643 7.46429ZM31.8929 9.39286C31.75 6.82143 31.1786 4.53571 29.3214 2.67857C27.4643 0.821429 25.1786 0.25 22.6071 0.107143C19.9643 -0.0357143 12.0357 -0.0357143 9.39286 0.107143C6.82143 0.25 4.60714 0.821429 2.67857 2.67857C0.821429 4.53571 0.25 6.82143 0.107143 9.39286C-0.0357143 12.0357 -0.0357143 19.9643 0.107143 22.6071C0.25 25.1786 0.821429 27.3929 2.67857 29.3214C4.60714 31.1786 6.82143 31.75 9.39286 31.8929C12.0357 32.0357 19.9643 32.0357 22.6071 31.8929C25.1786 31.75 27.4643 31.1786 29.3214 29.3214C31.1786 27.3929 31.75 25.1786 31.8929 22.6071C32.0357 19.9643 32.0357 12.0357 31.8929 9.39286ZM28.4643 25.3929C27.9643 26.8214 26.8214 27.8929 25.4643 28.4643C23.3214 29.3214 18.3214 29.1071 16.0357 29.1071C13.6786 29.1071 8.67857 29.3214 6.60714 28.4643C5.17857 27.8929 4.10714 26.8214 3.53571 25.3929C2.67857 23.3214 2.89286 18.3214 2.89286 15.9643C2.89286 13.6786 2.67857 8.67857 3.53571 6.53571C4.10714 5.17857 5.17857 4.10714 6.60714 3.53571C8.67857 2.67857 13.6786 2.89286 16.0357 2.89286C18.3214 2.89286 23.3214 2.67857 25.4643 3.53571C26.8214 4.03571 27.8929 5.17857 28.4643 6.53571C29.3214 8.67857 29.1071 13.6786 29.1071 15.9643C29.1071 18.3214 29.3214 23.3214 28.4643 25.3929Z" />
		</svg>
	)
}

export const Menu = ({ fill = defaultFill, height }) => {
	return (
		<svg viewBox="0 0 32 33" fill={fill} className={`icon icon-menu${height ? ` icon-${height}` : ""}`} xmlns="http://www.w3.org/2000/svg">
			<path d="M2 27.6667H30V24.5556H2V27.6667ZM2 17.8889H30V14.7778H2V17.8889ZM2 5V8.11111H30V5H2Z" />
		</svg>
	)
}

export const Minus = ({ fill = defaultFill, height }) => {
	return (
		<svg viewBox="0 0 12 3" fill={fill} className={`icon icon-minus${height ? ` icon-${height}` : ""}`} xmlns="http://www.w3.org/2000/svg">
			<path d="M0 2.54995V0.449951H12V2.54995H0Z" />
		</svg>
	)
}

export const Moped = ({ fill = defaultFill, height }) => {
	return (
		<svg viewBox="0 0 32 23" fill={fill} className={`icon icon-moped${height ? ` icon-${height}` : ""}`} xmlns="http://www.w3.org/2000/svg">
			<path d="M27.2 3.2C27.2 1.44 25.76 0 24 0H19.2V3.2H24V7.44L18.432 14.4H12.8V6.4H6.4C2.864 6.4 0 9.264 0 12.8V17.6H3.2C3.2 20.256 5.344 22.4 8 22.4C10.656 22.4 12.8 20.256 12.8 17.6H19.968L27.2 8.56V3.2ZM8 19.2C7.12 19.2 6.4 18.48 6.4 17.6H9.6C9.6 18.48 8.88 19.2 8 19.2Z" />
			<path d="M12.8 1.60004H4.79999V4.80004H12.8V1.60004Z" />
			<path d="M27.2 12.7999C24.544 12.7999 22.4 14.9439 22.4 17.5999C22.4 20.2559 24.544 22.3999 27.2 22.3999C29.856 22.3999 32 20.2559 32 17.5999C32 14.9439 29.856 12.7999 27.2 12.7999ZM27.2 19.1999C26.32 19.1999 25.6 18.4799 25.6 17.5999C25.6 16.7199 26.32 15.9999 27.2 15.9999C28.08 15.9999 28.8 16.7199 28.8 17.5999C28.8 18.4799 28.08 19.1999 27.2 19.1999Z" />
		</svg>
	)
}

export const Plus = ({ fill = defaultFill, height }) => {
	return (
		<svg viewBox="0 0 12 13" fill={fill} className={`icon icon-plus${height ? ` icon-${height}` : ""}`} xmlns="http://www.w3.org/2000/svg">
			<path d="M5.05906 0.5H6.94094V5.68126H12V7.31874H6.94094V12.5H5.05906V7.31874H0V5.68126H5.05906V0.5Z" />
		</svg>
	)
}

export const Star = ({ fill = defaultFill, height, variant = "fill" }) => {
	if (variant === "fill") {
		return (
			<svg viewBox="0 0 32 31" fill={fill} className={`icon icon-star-fill${height ? ` icon-${height}` : ""}`} xmlns="http://www.w3.org/2000/svg">
				<path d="M16 24.6987L25.888 30.6667L23.264 19.4187L32 11.8507L20.496 10.8747L16 0.266663L11.504 10.8747L0 11.8507L8.736 19.4187L6.112 30.6667L16 24.6987Z" />
			</svg>
		)
	}

	if (variant === "outline") {
		return (
			<svg viewBox="0 0 32 31" fill={fill} className={`icon icon-star-outline${height ? ` icon-${height}` : ""}`} xmlns="http://www.w3.org/2000/svg">
				<path d="M32 11.8505L20.496 10.8585L16 0.266541L11.504 10.8745L0 11.8505L8.736 19.4185L6.112 30.6665L16 24.6985L25.888 30.6665L23.28 19.4185L32 11.8505ZM16 21.7065L9.984 25.3385L11.584 18.4905L6.272 13.8825L13.28 13.2745L16 6.82654L18.736 13.2905L25.744 13.8985L20.432 18.5065L22.032 25.3545L16 21.7065Z" />
			</svg>
		)
	}
}

export const Twitter = ({ fill = defaultFill, height }) => {
	return (
		<svg viewBox="0 0 32 26" fill={fill} className={`icon icon-twitter${height ? ` icon-${height}` : ""}`} xmlns="http://www.w3.org/2000/svg">
			<path d="M28.7436 6.4501C28.7436 6.76321 28.7436 7.0137 28.7436 7.32681C28.7436 16.0313 22.1683 25.9883 10.0822 25.9883C6.32485 25.9883 2.88063 24.9237 0 23.045C0.500978 23.1076 1.00196 23.1703 1.56556 23.1703C4.63405 23.1703 7.45206 22.1057 9.70646 20.3523C6.82583 20.2896 4.38356 18.411 3.56947 15.7808C4.00783 15.8434 4.38356 15.9061 4.82192 15.9061C5.38552 15.9061 6.01174 15.7808 6.51272 15.6556C3.50685 15.0294 1.25245 12.3992 1.25245 9.20548V9.14286C2.12916 9.64384 3.19374 9.89433 4.25832 9.95695C2.44227 8.76712 1.31507 6.76321 1.31507 4.50881C1.31507 3.25636 1.62818 2.12916 2.19178 1.18982C5.44814 5.13503 10.3327 7.76517 15.7808 8.07828C15.6556 7.5773 15.593 7.07632 15.593 6.57534C15.593 2.94325 18.5362 0 22.1683 0C24.047 0 25.7378 0.751468 26.9902 2.06654C28.4305 1.75342 29.8708 1.18982 31.1233 0.438356C30.6223 2.00391 29.6204 3.25636 28.2427 4.07045C29.5577 3.94521 30.8728 3.56947 32 3.06849C31.1233 4.38356 29.9961 5.51076 28.7436 6.4501Z" />
		</svg>
	)
}

export const User = ({ fill = defaultFill, height }) => {
	return (
		<svg viewBox="0 0 24 24" fill={fill} className={`icon icon-user${height ? ` icon-${height}` : ""}`} xmlns="http://www.w3.org/2000/svg">
			<path d="M12 12C15.315 12 18 9.315 18 6C18 2.685 15.315 0 12 0C8.685 0 6 2.685 6 6C6 9.315 8.685 12 12 12ZM12 15C7.995 15 0 17.01 0 21V24H24V21C24 17.01 16.005 15 12 15Z" />
		</svg>

	)
}

export const Youtube = ({ fill = defaultFill, height }) => {
	return (
		<svg viewBox="0 0 32 23" fill={fill} className={`icon icon-youtube${height ? ` icon-${height}` : ""}`} xmlns="http://www.w3.org/2000/svg">
			<path d="M31.298 3.56856C30.947 2.16453 29.8355 1.05302 28.4899 0.702011C25.9744 0 16.0293 0 16.0293 0C16.0293 0 6.02559 0 3.51005 0.702011C2.16453 1.05302 1.05302 2.16453 0.702011 3.56856C0 6.02559 0 11.2907 0 11.2907C0 11.2907 0 16.4973 0.702011 19.0128C1.05302 20.4168 2.16453 21.4698 3.51005 21.8208C6.02559 22.4644 16.0293 22.4644 16.0293 22.4644C16.0293 22.4644 25.9744 22.4644 28.4899 21.8208C29.8355 21.4698 30.947 20.4168 31.298 19.0128C32 16.4973 32 11.2907 32 11.2907C32 11.2907 32 6.02559 31.298 3.56856ZM12.7532 16.0293V6.5521L21.0603 11.2907L12.7532 16.0293Z" />
		</svg>
	)
}